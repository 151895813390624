<ng-template [ngIf]="menu">
  <ng-container [brManageMenuButton]="menuModel"></ng-container>
  <div class="fti-container text-center site-toggle" *ngIf="siteToggle">
    <span class="tog-new">
      <span>New</span>
    </span>
    <span [innerHTML]="siteToggleVal"></span>
  </div>

  <div
    class="header-section"
    [ngClass]="{ 'has-edit-button': page?.isPreview() }"
  >
    <!-- Web View -->
    <div class="webView">
      <!-- Top Menu -->
      <div class="headerTopMenu">
        <div class="fti-container">
          <nav class="navbar navbar-expand-lg navbar-dark p-0">
            <div class="container-fluid">
              <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                  <li
                    tabindex="0"
                    class="nav-link-menu"
                    *ngFor="let topMenu of menu?.topMenuItems?.childMenuItems"
                    [ngClass]="{ dropdown: topMenu?.childMenuItems?.length }"
                  >
                    <div
                      *ngIf="
                        topMenu?.childMenuItems?.length;
                        then withMenuDropdown;
                        else withoutMenuDropdown
                      "
                    ></div>

                    <ng-template #withMenuDropdown>
                      <a
                        class="nav-link dropdown-toggle top-navigation"
                        id="navbarDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        >{{ topMenu.name }}
                        <span
                          class="icon-down icon icon-fti_downArrow float-end"
                        ></span>
                        <span class="icon-up icon icon-fti_upArrow"></span>
                      </a>
                    </ng-template>
                    <ng-template #withoutMenuDropdown>
                      <a
                        class="nav-link top-navigation"
                        *ngIf="!topMenu.links?.site"
                        >{{ topMenu.name }}
                      </a>
                      <a
                        class="nav-link top-navigation"
                        *ngIf="topMenu.links?.site | isExternalLink"
                        [href]="topMenu.links.site?.href"
                        [target]="topMenu | isNewPage"
                        >{{ topMenu.name }}
                      </a>
                      <a
                        class="nav-link top-navigation"
                        *ngIf="topMenu.links?.site | isInternalLink"
                        [target]="topMenu | isNewPage"
                        [routerLink]="page?.getUrl(topMenu.links?.site?.href)"
                        >{{ topMenu.name }}
                      </a>
                    </ng-template>

                    <ng-container *ngIf="topMenu?.childMenuItems?.length">
                      <ul
                        class="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <li
                          *ngFor="let childMenuItem of topMenu?.childMenuItems"
                        >
                          <a
                            class="dropdown-item top-sub-navigation"
                            *ngIf="!childMenuItem.links?.site"
                            >{{ childMenuItem.name }}
                          </a>
                          <a
                            class="dropdown-item top-sub-navigation"
                            *ngIf="childMenuItem.links?.site | isExternalLink"
                            [href]="childMenuItem.links.site?.href"
                            [target]="childMenuItem | isNewPage"
                            >{{ childMenuItem.name }}
                          </a>
                          <a
                            class="dropdown-item top-sub-navigation"
                            *ngIf="childMenuItem.links?.site | isInternalLink"
                            [target]="childMenuItem | isNewPage"
                            [routerLink]="
                              page?.getUrl(childMenuItem.links?.site?.href)
                            "
                            >{{ childMenuItem.name }}
                          </a>
                        </li>
                      </ul>
                    </ng-container>
                  </li>
                  <li class="nav-link-menu dropdown">
                    <a
                      class="nav-link dropdown-toggle top-navigation"
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      ><span class="sr-only">Country</span>
                      <img
                        class="nav-flag"
                        src="/assets/images/fti_country_india.png"
                        alt="India, click to change location"
                        title="India, click to change location "
                      />
                      <span
                        class="icon-down icon icon-fti_downArrow float-end"
                      ></span>
                      <span class="icon-up icon icon-fti_upArrow"></span>
                    </a>

                    <ul
                      class="dropdown-menu countryUl"
                      aria-labelledby="navbarDropdown"
                    >
                      <li>
                        <p>{{ "ftiCommon.countryMenuLabel" | translate }}</p>
                        <a
                          href="{{ 'ftiCommon.countryMenuUrl' | translate }}"
                          class="btn btn-primary top-sub-navigation"
                          >{{ "ftiCommon.countryMenuChoose" | translate }}</a
                        >
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>

      <!-- Logo and Search -->
      <div class="headerLogoSearch">
        <div class="fti-container">
          <div class="container-fluid">
            <a
              class="float-start logo-click"
              aria-label="Franklin Templeton logo"
              [routerLink]="page?.getUrl('/')"
            >
              <img
                class="img-responsive"
                src="/assets/images/fti_logo.png"
                alt="Franklin Templeton Investments"
                title="Franklin Templeton Investments"
              />
            </a>
            <div class="search-bar siteSearch-cont float-end col-md-5 pr0">
              <form
                class="float-end siteSearch-form"
                role="search"
                *ngIf="!isMobile"
              >
                <div class="form-group">
                  <ng-container [ngTemplateOutlet]="searchBoxInput">
                  </ng-container>
                  <a
                    href="javascript:void(0)"
                    class="fti-searchBtn icon-fti_search btn-inset"
                    data-searchincategory="all"
                    value="search"
                    aria-label="Search"
                    (click)="navigateToSearchResults()"
                  >
                    <span class="sr-only"></span>
                  </a>
                  <ft-auto-suggestions-list
                    *ngIf="showAutoSuggestions"
                    [searchText]="searchText"
                    [triggerChange]="triggerChange"
                  ></ft-auto-suggestions-list>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <!-- Main Menu -->
      <div class="headerMainMenu">
        <div class="fti-container">
          <nav class="navbar navbar-expand-lg navbar-dark p-0 navMainMenu">
            <div class="container-fluid">
              <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#mainMenuDropdown"
                aria-controls="mainMenuDropdown"
                aria-expanded="false"
                aria-label="Toggle Main menu navigation"
              >
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse" id="mainMenuDropdown">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                  <li
                    tabindex="0"
                    class="nav-link-menu"
                    *ngFor="let mainMenu of menu?.mainMenuItems"
                    [ngClass]="{ dropdown: mainMenu?.childMenuItems?.length }"
                  >
                    <div
                      *ngIf="
                        mainMenu?.childMenuItems?.length;
                        then withDropdownMain;
                        else withoutDropdownMain
                      "
                    ></div>

                    <ng-template #withDropdownMain>
                      <a
                        class="nav-link dropdown-toggle top-navigation"
                        id="navbarMenuDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        >{{ mainMenu.name }}
                      </a>
                    </ng-template>

                    <ng-template #withoutDropdownMain>
                      <a
                        class="nav-link top-navigation"
                        *ngIf="!mainMenu.links.site"
                        >{{ mainMenu.name }}
                      </a>
                      <a
                        class="nav-link top-navigation"
                        *ngIf="mainMenu.links?.site | isExternalLink"
                        [href]="getUrl(mainMenu)"
                        [target]="mainMenu | isNewPage"
                        >{{ mainMenu.name }}
                      </a>
                      <a
                        class="nav-link top-navigation"
                        *ngIf="
                          (mainMenu.parameters[
                            'Request parameter (key=value)'
                          ] === 'app=externalUrl' &&
                            mainMenu.parameters[
                              'Request parameter (key=value)'
                            ] !== 'app=redirectGuestUrl') ||
                            mainMenu.links?.site | isInternalLink
                        "
                        [target]="mainMenu | isNewPage"
                        [routerLink]="page?.getUrl(mainMenu.links?.site?.href)"
                        >{{ mainMenu.name }}
                      </a>
                    </ng-template>

                    <ng-container *ngIf="mainMenu?.childMenuItems?.length">
                      <ul
                        class="dropdown-menu"
                        aria-labelledby="navbarMenuDropdown"
                      >
                        <li
                          class="col-sm-3 float-start"
                          *ngFor="
                            let parentMenuItem of mainMenu?.childMenuItems
                          "
                          [ngClass]="
                            advisorHideLink(parentMenuItem) +
                            ' ' +
                            advisorShowLink(parentMenuItem)
                          "
                        >
                          <ul>
                            <ng-container *ngIf="!parentMenuItem.length">
                              <li
                                [ngClass]="{
                                  dropdownHeading: !parentMenuItem?.links?.site
                                }"
                              >
                                {{ parentMenuItem.name }}
                              </li>
                            </ng-container>
                            <ng-container *ngIf="parentMenuItem.length">
                              <li
                                *ngFor="let item of parentMenuItem"
                                [ngClass]="
                                  advisorHideLink(item) +
                                  ' ' +
                                  advisorShowLink(item)
                                "
                              >
                                <a
                                  *ngIf="!item.links.site"
                                  class="main-sub-navigation"
                                  >{{ item.name }}</a
                                >
                                <a
                                  *ngIf="item.links?.site | isExternalLink"
                                  [href]="getUrl(item)"
                                  [target]="item | isNewPage"
                                  class="main-sub-navigation"
                                  >{{ item.name }}
                                </a>
                                <a
                                  *ngIf="
                                    (item.parameters[
                                      'Request parameter (key=value)'
                                    ] === 'app=externalUrl' &&
                                      item.parameters[
                                        'Request parameter (key=value)'
                                      ] !== 'app=redirectGuestUrl') ||
                                      item.links?.site | isInternalLink
                                  "
                                  class="main-sub-navigation"
                                  [target]="item | isNewPage"
                                  [routerLink]="
                                    page?.getUrl(item.links?.site?.href)
                                  "
                                  >{{ item.name }}
                                </a>
                              </li>
                            </ng-container>

                            <ng-container
                              *ngIf="parentMenuItem?.childMenuItems?.length"
                            >
                              <li
                                *ngFor="
                                  let childMenuItem of parentMenuItem?.childMenuItems
                                "
                                [ngClass]="
                                  advisorHideLink(childMenuItem) +
                                  ' ' +
                                  advisorShowLink(childMenuItem)
                                "
                              >
                                <a
                                  class="main-sub-navigation"
                                  *ngIf="!childMenuItem.links?.site"
                                  >{{ childMenuItem.name }}</a
                                >
                                <a
                                  class="main-sub-navigation"
                                  *ngIf="
                                    !(
                                      childMenuItem.parameters[
                                        'Request parameter (key=value)'
                                      ] | IsDataTarget
                                    ) && childMenuItem.links?.site
                                      | isExternalLink
                                  "
                                  [target]="childMenuItem | isNewPage"
                                  [href]="getUrl(childMenuItem)"
                                  >{{ childMenuItem.name }}
                                </a>
                                <a
                                  *ngIf="
                                    childMenuItem.parameters[
                                      'Request parameter (key=value)'
                                    ] | IsDataTarget
                                  "
                                  class="main-sub-navigation"
                                  data-bs-toggle="modal"
                                  [attr.data-bs-target]="
                                    childMenuItem.parameters[
                                      'Request parameter (key=value)'
                                    ] | DataTargetValue
                                  "
                                  href="#"
                                  >{{ childMenuItem.name }}
                                </a>
                                <a
                                  class="main-sub-navigation"
                                  *ngIf="
                                    (childMenuItem.parameters[
                                      'Request parameter (key=value)'
                                    ] === 'app=externalUrl' &&
                                      childMenuItem.parameters[
                                        'Request parameter (key=value)'
                                      ] !== 'app=redirectGuestUrl') ||
                                      childMenuItem.links?.site | isInternalLink
                                  "
                                  [target]="childMenuItem | isNewPage"
                                  [routerLink]="
                                    page?.getUrl(
                                      childMenuItem.links?.site?.href
                                    )
                                  "
                                  >{{ childMenuItem.name }}
                                </a>
                              </li>
                            </ng-container>
                          </ul>
                        </li>
                      </ul>
                    </ng-container>
                  </li>
                </ul>

                <!-- Login Button -->
                <div class="fti-loginBtn" *ngIf="!isLoggedIn">
                  <div class="navbar-nav">
                    <div class="nav-link-menu dropdown">
                      <a
                        class="fti-loginBtn nav-link dropdown-toggle"
                        role="button"
                        data-bs-toggle="dropdown"
                        type="button"
                        tabindex="0"
                      >
                        <span class="icon-fti_profile"></span>
                        {{ "ftiLoginRegister.loginRegister" | translate }}
                        <span class="icon icon-fti_downArrow float-end"></span>
                        <span class="icon icon-fti_upArrow float-end"></span>
                      </a>

                      <div
                        class="dropdown-menu fti-loginForm"
                        (click)="showHidePopUp($event)"
                      >
                        <ft-login-register-form
                          *ngIf="!isMobile"
                          [loginRegisterData]="loginRegisterData"
                          [page]="page"
                        ></ft-login-register-form>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Log Out -->
                <ul
                  *ngIf="isLoggedIn"
                  class="fti-navbar-login navbar-nav col-md-3 pull-right pr-0 justify-content-end"
                >
                  <li
                    class="fti-navbar-login-item nav-item pr-0 pl-0 ng-star-inserted"
                  >
                    <a
                      class="nav-link fti-myaccount-btn analytics-link"
                      (click)="myAccounts()"
                      [innerHTML]="'ftiLoginRegister.myAccounts' | translate"
                    ></a>
                  </li>
                  <li
                    class="fti-navbar-login-item nav-item pr-0 pl-0 ng-star-inserted"
                  >
                    <a
                      class="nav-link fti-logout-btn analytics-link"
                      (click)="signOut()"
                    >
                      <span
                        _ngcontent-hhy-c8=""
                        class="icon icon-fti-Logout"
                      ></span>
                      {{ "ftiLoginRegister.logOut" | translate }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>

    <!-- Mobile View -->
    <div class="mobileView">
      <div class="header" #mobheader id="mobileHeader">
        <div class="mobile-container" id="mobContainer">
          <nav class="navbar navbar-expand-lg navbar-dark p-0 float-start">
            <div id="fti-nav_mainMenu">
              <button
                class="navbar-toggler"
                type="button"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span aria-hidden="true" class="icon icon-fti_close"></span>
              </button>

              <div
                class="mobileMenu"
                id="navbarSupportedContentMobile"
                #mobmenu
              ></div>
              <div class="nav-overlay d-none"></div>
            </div>
          </nav>

          <!-- Logo -->
          <a
            tabindex="0"
            class="navbar-brand logo-click"
            data-event-name="Event All pages"
            data-category="Top Navigation"
            data-action="Logo CLick"
            data-label="baseUrl"
            href="/"
          >
            <img
              src="/assets/images/fti_logo_white.png"
              alt="Franklin Templeton Investments "
              title="Franklin Templeton Investments"
            />
          </a>
          <!-- Login/Register -->
          <div *ngIf="!isLoggedIn" class="loginBtn" (click)="callLoginBox()">
            <a
              href="javascript:void(0)"
              class="icon fti-loginBtn float-end btn profile icon-fti_profile"
              aria-label="Login"
              tabindex="0"
            >
            </a>

            <div class="overlay" [ngClass]="{ show: isLoginBox }">
              <div
                class="fti-loginForm"
                [ngClass]="{ show: isLoginBox }"
                (click)="showHidePopUp($event)"
              >
                <ft-login-register-form
                  *ngIf="isMobile"
                  [loginRegisterData]="loginRegisterData"
                  [page]="page"
                ></ft-login-register-form>
              </div>
            </div>
          </div>
          <!-- LogOut -->
          <div *ngIf="isLoggedIn" class="loginBtn" (click)="signOut()">
            <a
              href="javascript:void(0)"
              class="icon fti-loginBtn float-end btn profile icon-fti-Logout"
              aria-label="
              Logout"
              tabindex="0"
            >
            </a>
          </div>
          <!-- Search -->
          <div class="search-box float-end">
            <div class="row">
              <div class="col-sm-12">
                <div class="mb-1 mt-2 siteSearch-form">
                  <form
                    class="siteSearch-form"
                    role="search"
                    *ngIf="!isMobileMin && isMobile"
                  >
                    <ng-container [ngTemplateOutlet]="searchBoxInput">
                    </ng-container>
                    <a
                      href="javascript:void(0)"
                      class="icon fti-searchBtn icon-fti_search search-btn-form"
                      value="search"
                      aria-label="Search"
                      (click)="navigateToSearchResults()"
                    >
                      <span class="sr-only"></span>
                    </a>
                    <ft-auto-suggestions-list
                      *ngIf="showAutoSuggestions"
                      [searchText]="searchText"
                      [triggerChange]="triggerChange"
                    ></ft-auto-suggestions-list>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="search-box-popup" (click)="callSearchBox()">
            <a
              href="javascript:void(0)"
              class="icon fti-searchBtn icon-fti_search btn-inset"
              value="search"
              aria-label="Search"
            >
              <span class="sr-only"></span>
            </a>
            <div class="overlay" [ngClass]="{ show: isSearchBox }">
              <div
                class="search-box-popup-form"
                (click)="$event.stopPropagation()"
              >
                <form
                  class="float-end siteSearch-form"
                  role="search"
                  *ngIf="isMobileMin"
                >
                  <div class="form-group">
                    <ng-container [ngTemplateOutlet]="searchBoxInput">
                    </ng-container>
                    <a
                      href="javascript:void(0)"
                      class="icon glyphicon glyphicon-search search-btn-form"
                      value="search"
                      aria-label="Search"
                      (click)="navigateToSearchResults()"
                    >
                      <span class="sr-only"></span>
                    </a>
                    <ft-auto-suggestions-list
                      *ngIf="showAutoSuggestions"
                      [searchText]="searchText"
                      [triggerChange]="triggerChange"
                    ></ft-auto-suggestions-list>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <ng-template #searchBoxInput>
            <input
              type="text"
              aria-label="Search"
              autocomplete="off"
              class="form-control search"
              placeholder="Search"
              tabindex="0"
              [(ngModel)]="searchText"
              (click)="
                searchText?.length >= 3
                  ? (showAutoSuggestions = true)
                  : (showAutoSuggestions = false)
              "
              #searchBarInput
            />
          </ng-template>
        </div>
      </div>
    </div>
    <div class="feedbackMsg" *ngIf="feedbackThankYou">
      <div class="fti-container">
        {{ "ftiFeedback.flashMessage" | translate }}
      </div>
    </div>
  </div>
</ng-template>

<!-- Feedback modal  -->
<ng-template #feedbackModal>
  <ft-alert-modal
    [hasAlertIcon]="true"
    [modalBodyText]="'ftiLoginRegister.provideFeedback' | translate"
    [primaryButtonText]="'ftiLoginRegister.provideFeedbackSubmit' | translate"
    [secondaryButtonText]="'ftiLoginRegister.provideFeedbackLogout' | translate"
    (modalDisplayed)="closeFeedbackModal($event)"
    (primaryButtonEvent)="goToFeedback()"
    (secondaryButtonEvent)="signOut(true)"
  ></ft-alert-modal>
</ng-template>

<!-- Session End modal -->
<ng-template #sessionModal>
  <ft-alert-modal
    [hasCloseButton]="false"
    [hasAlertIcon]="true"
    [modalBodyText]="'ftiLoginRegister.sessionEnd' | translate"
    [primaryButtonText]="'ftiLoginRegister.continue' | translate"
    [secondaryButtonText]="'ftiLoginRegister.ignore' | translate"
    (primaryButtonEvent)="continueSession()"
    (secondaryButtonEvent)="ignoreSession()"
  ></ft-alert-modal>
</ng-template>
